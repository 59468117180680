/*@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-notifications/styles/material.css";
*/
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-notifications/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap5.css";
a {
  color: #0366d6;
}

html,
body {
  height: 100vh;
}

.close {
  border: none;
  background-color: #fff;
  opacity: 0.5;
  transition: ease-in-out all 0.3s;
}
.close:hover {
  opacity: 1;
}

.error-message {
  font-size: 0.75em;
  color: #f00;
  font-style: italic;
}

.bg-blue {
  background: #201d39 !important;
  color: #fff;
}

.border-blue {
  border: #201d39 !important;
}

.btn-active {
  background: #201d39 !important;
  color: #fff !important;
  width: 200px;
  height: 50px;
  border-radius: 15px;
}

.btn-blue {
  background: #201d39 !important;
  color: #fff;
  width: 200px;
  height: 50px;
  border-radius: 15px;
}

.btn-inactive {
  background: #929292 !important;
  color: #000 !important;
  width: 200px;
  height: 50px;
  border-radius: 15px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

code {
  color: #e01a76;
}

.dms-header {
  border: 2px solid #201d39;
  border-radius: 10px;
}

.login {
  height: 100vh;
  display: grid;
  align-items: center;
}

.login-card {
  background-color: #9bd8f2 !important;
  border: 2px solid #000000 !important;
  box-shadow: 0px 0px 10px 0px #000000 !important;
}

.login-card h3 {
  font-size: 1.25em;
  margin: 0;
  padding: 0;
}

.text-blue {
  color: #2b2849 !important;
}

.text-orange {
  color: #df6f6b !important;
}

u {
  text-decoration-thickness: 6px;
}

.text-thin {
  font-weight: 100 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  border-radius: 0 !important;
}
.dropdown-menu.show {
  background-color: #9bd8f2;
  border: 2px solid #000000 !important;
  box-shadow: 0px 0px 10px 0px #000000;
  text-align: left;
}
.dropdown-menu .nav-link {
  padding: 0;
}

.dropdown-item:hover {
  background-color: #df6f6b !important;
  color: #fff !important;
}

.table-container {
  /* Set a maximum height for your container */
  /* Enable vertical scrolling */
  /* Hide the scrollbar in Firefox */
}

/* Hide the scrollbar in WebKit-based browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
  /*width: 0;
  height: 0;*/
}

.big-checkbox {
  width: 2em;
  height: 2em;
}

.table-input {
  border-top: none;
  background-color: transparent !important;
  width: 100% !important;
  height: 50px !important;
  padding: 5px;
  border-left: 1px solid #201d39 !important;
  border-bottom: 1px solid #201d39 !important;
  font-size: 25px;
}

.input {
  padding: 0px !important;
  border: 1px solid #201d39;
  border-top: none;
  border-bottom: none;
  font-size: 25px;
  border-left: none;
  border-right: none !important;
  outline: none !important;
}

.table-dropdown {
  border-bottom: 1px solid #201d39 !important;
  margin: 0px !important;
  padding: 0px !important;
}

.error {
  color: red;
  font-size: 0.75em;
  font-style: italic;
  display: inline;
}

.fa-edit,
.fa-trash {
  cursor: pointer;
  font-size: 18px;
}

table {
  /*table-layout: fixed;*/
}

.btn-primary {
  background-color: #201d39 !important;
  box-shadow: 0 4px 9px -4px #201d39 !important;
}
.btn-primary:hover {
  box-shadow: 0 8px 9px -4px #201d39 !important;
}

.btn-pastel-blue {
  color: #201d39 !important;
  background-color: #9bd8f2 !important;
  box-shadow: 0 4px 9px -4px #9bd8f2 !important;
}
.btn-pastel-blue:hover {
  box-shadow: 0 8px 9px -4px #9bd8f2 !important;
}

.graph-filter {
  border: 1px dashed #ccc;
  text-align: center;
}

.graph-filter.active {
  background-color: rgba(32, 29, 57, 0.4117647059);
  color: #fff;
}

.date-picker {
  border: none;
  text-align: center;
}

.site-title {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 13px;
  margin-top: 12px;
}
.site-title span {
  color: #d72e27;
}

.loading {
  position: fixed;
  display: block;
  z-index: 10000;
  /*height: 2em;
  width: 2em;*/
  /*overflow: show;
  margin: auto;*/
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  /*&:before {
    //content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }*/
  color: #fff;
  text-align: center;
  font-size: 2em;
  padding-top: 80px;
}

.nav-chevron {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.search-result-item {
  border-bottom: #201d39 1px solid;
  margin-bottom: 12px;
}
.search-result-item:hover {
  background-color: #eee;
  border-bottom: #201d39 2px solid;
}

@media (max-width: 768px) {
  .nav-chevron {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body {
    width: 100vw;
    overflow-x: scroll;
    /* Prevent horizontal overflow */
    overflow-y: auto;
  }
  .table-container {
    zoom: 50%;
  }
}
.card-footer.counter {
  font-size: 1.5em;
  color: #201d39;
}

.e-headercell {
  background: #201d39 !important;
  color: #fff !important;
}
.e-headercell.e-focus {
  color: #f0f !important;
}
.e-headercell.e-focus .e-headertext {
  color: #df6f6b !important;
}

.css-b62m3t-container.select2-sm {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}
.css-b62m3t-container.select2-sm .css-13cymwt-control,
.css-b62m3t-container.select2-sm .css-t3ipsp-control {
  min-height: 20px !important;
}
.css-b62m3t-container.select2-sm .css-13cymwt-control .css-1fdsijx-ValueContainer,
.css-b62m3t-container.select2-sm .css-t3ipsp-control .css-1fdsijx-ValueContainer {
  padding: 0 8px !important;
}
.css-b62m3t-container.select2-sm .css-13cymwt-control .css-1xc3v61-indicatorContainer, .css-b62m3t-container.select2-sm .css-13cymwt-control .css-1hb7zxy-indicatorContainer,
.css-b62m3t-container.select2-sm .css-t3ipsp-control .css-1xc3v61-indicatorContainer,
.css-b62m3t-container.select2-sm .css-t3ipsp-control .css-1hb7zxy-indicatorContainer {
  padding: 4px !important;
}
