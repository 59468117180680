a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/*html {
  font-size: 12px;

}
*/
h5 {
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  /*html {
    font-size: 14px;
  }

  h5 {
    font-size: 1.25rem;
    padding: 0;
    margin: 0;
  }*/
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
